import React,{useEffect,useState,useContext} from 'react';
import { FaCity, FaDraftingCompass, FaHardHat, FaRegBuilding, FaTruckMoving } from 'react-icons/fa';
import axios from 'axios';

import servicesTwoData from './servicesTwoData';
import ServicesTwoCard from './SevicesTwoCard';

import thumb1 from '../../assets/img/service8.jpg';
import { UserContext } from '../../App';
import { useApi } from '../../middleware/middleware';


function Services8() {
    const {values} = useContext(UserContext)

    const { data, error, isLoading } = useApi('testfabrics_standard_products_by_subcategory', {methods:values?.sucategoryId,standards:values?.categoryId});

    // const [produts,setProducts] = useState(null)
    // useEffect(()=>{
    //     axios.get(process.env.REACT_APP_API_URL+"testfabrics_products_by_subcategory",{params:{catID:"205"}})
    //     .then(res=>{console.log(res);setProducts(res?.data)})
    // },[])
    return (
        <section className="our-service-wrapper section-padding mtm-30">
            <div className="container">
                <div className="row">
                    {data?.length==0&&<h4>No Products Found</h4>}
                    {data?.map((data) => (
                        <ServicesTwoCard
                            key={data.product__ID}
                            productId={data.product__ID}
                            thumbnail={data.product__ID}
                            defaultImg={process.env.REACT_APP_IMAGE_URL + 'images/product_testfabrics.jpg'}
                            icon={<FaDraftingCompass />}
                            heading={data.product__Name}
                            text={data?.product__Description?.slice(0, 95)+"..."}
                            subheading={data?.product__Number}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Services8;
