import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import DetailModel from './model';

function BlogOneItem({ thumb, title, description }) {
    

    const imageUrl = process.env.REACT_APP_IMAGE_URL+"partners_images/"+suCrypt()+".jpg";
    function suCrypt() {
        return btoa(btoa(thumb));
    }
    const [countryId, setCountryId] = useState({data:"",show:false});
    function handleClick(id,name){
        setCountryId(()=>({data:description,show:true}));
        // setModalShow(true);
    }
    return (
        <>
            <DetailModel
                show={countryId.show}
                onHide={() => setCountryId((pre)=>({...pre,show:false}))}
                data={countryId?.data}
                title={title}
                thumbnail={imageUrl}
            />
            <div className="col-lg-4 col-md-6 col-12">
                <div className="single-blog-card" onClick={handleClick} style={{cursor:"pointer"}}>
                    <div
                        className="featured-img bg-cover"
                        style={{
                            // backgroundColor:"#fff",
                            backgroundImage: `url(${imageUrl})`,
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                        }}
                    >
                        {/* <img src={imageUrl} /> */}
                        </div>
                    <div className="post-content">
                        {/* <div className="post-date">
                            <span>{date}</span>
                            {month}
                        </div> */}
                        <div className="post-meta">
                            {/* <Link to={link} className="post-cat">
                                {category}
                            </Link>{' '} */}
                            
                            {/* <Link to={link} className="post-author">
                                {author}
                            </Link> */}
                        </div>
                        <h3>
                            {title}
                        </h3>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BlogOneItem;
