//import React from 'react';
//import bannerBg from '../assets/img/page-banner.jpg';
//import Footer1 from '../components/Footer1';
//import Header1 from '../components/Header1';
//import PageBanner from '../components/PageBanner';
//import ServiceDetails from '../components/ServiceDetails';
//import ServiceSidebar from '../components/ServiceSidebar';
//import Header3 from '../components/Header3';
//import DetailsCarousel from '../components/ServiceDetails/DetailsCarousel';
//import Footer3 from '../components/Footer3';
//import { Link } from 'react-router-dom/cjs/react-router-dom';
//import { useApi } from '../middleware/middleware';
//import { useLocation } from 'react-router-dom';
//import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

//function ServicesDetails() {
//    const location= useLocation()
//    const history = useHistory();

//    const pathData = location?.pathname;

//    const words = pathData?.split("-");
//    // Get the last word that starts with a hyphen
//    const lastHyphenatedWord = words?.reverse()?.find(word => word.startsWith("-"));
//    // If no word starting with hyphen is found, handle it gracefully
//    const lastWord = words[0];
//        const { data, error, isLoading } = useApi('product_breadcrumb', {id:lastWord});
//        const { data:associated, error:associatedError, isLoading:associatedLoading } = useApi('associated_testing_standards', {id:lastWord});
//        const { data:associated2, errorassociatedErrors2, isLoading:associatedLoading2 } = useApi('associated_testing_methods', {id:lastWord});

//    if (error || associatedError || errorassociatedErrors2) {
//        console.error("API Error:", error || associatedError || errorassociatedErrors2);
//    }
//        function onClickHandle(url,heading,Id){
//            const nameReplace = heading.replaceAll("/", "");
//        const urlHeading = nameReplace.replaceAll(" ", "-")+"-"+(Id);

//        history.push(`/${url}/${urlHeading}`)
//        }

//    return (
//        <>
//            <Header3 />
//            <section
//                className="page-banner-wrap-2 bg-cover"
//                style={{ background: "white"}}
//            >
//                <div className="container">
//                    <div className="row">
//                        <div className="col-12 col-lg-12">
//                            <div className="breadcrumb-wrap">
//                                <nav>
//                                    <ol className="breadcrumb">
//                                        <li className="breadcrumb-item">
//                                            <Link to="/">Home</Link>
//                                        </li>
//                                        <li className="breadcrumb-item active" aria-current="page" onClick={()=>onClickHandle("product-by-subcategory",data?.[0]?.category__Name,data?.[0]?.category__ID)}>
//                                            {"Product Details"}
//                                            {data?.[0]?.category__Name}
//                                        </li>
//                                        <li className="breadcrumb-item active" aria-current="page" onClick={()=>onClickHandle("product",data?.[0]?.subcategory__Name,data?.[0]?.subcategory__ID)}>
//                                            {"Product Details"}
//                                            {data?.[0]?.subcategory__Name}
//                                        </li>
//                                        <li className="breadcrumb-item active" aria-current="page">
//                                            {"Product Details"}
//                                            {data?.[0]?.product__Name}
//                                        </li>
//                                    </ol>
//                                </nav>
//                            </div>

//                            {/* <div className="page-heading text-white">
//                                <h1>{heading}</h1>
//                            </div> */}
//                        </div>
//                    </div>
//                </div>
//            </section>
//            <section className="service-details-wrapper ">
//                <div className="container">
//                    <div className="row">
//                        <div className="col-lg-6 col-md-5 mt-5 mt-md-0 col-12 order-1 order-md-1">
//                            <div className="service-details-content-wrapper pl-0 pl-md-4">
//                                <DetailsCarousel />
//                                <div>
//                                    <div>
//                                        <h6>Associated to Testing Standards:{associated}</h6>
//                                    </div>
//                                    <div>
//                                        <h6>Associated to Test Method(s):{associated2}</h6>
//                                    </div>

//                                </div>
//                            </div>

//                        </div>
//                        <div className="col-lg-5 col-md-5 col-12 order-2 order-md-2" style={{height:"100vh",overflow:"scroll",overflowX:"hidden"}}>
//                            <ServiceDetails />
//                        </div>                        
//                    </div>
//                </div>
//            </section>
//            <Footer3 />
//        </>
//    );
//}

//export default ServicesDetails;




import React from 'react';
import bannerBg from '../assets/img/page-banner.jpg';
import Footer1 from '../components/Footer1';
import Header1 from '../components/Header1';
import PageBanner from '../components/PageBanner';
import ServiceDetails from '../components/ServiceDetails';
import ServiceSidebar from '../components/ServiceSidebar';
import Header3 from '../components/Header3';
import DetailsCarousel from '../components/ServiceDetails/DetailsCarousel';
import Footer3 from '../components/Footer3';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { useApi } from '../middleware/middleware';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function ServicesDetails() {
    const location = useLocation();
    const history = useHistory();

    // Log location and pathname
    console.log('Location:', location);
    const pathData = location?.pathname;

    const words = pathData?.split("-");
    console.log('Words:', words);
    const lastWord = words[words.length - 1];

    // Log lastWord to ensure it's correctly derived
    console.log('Extracted lastWord:', lastWord);

    // Make API calls
    const { data, error, isLoading } = useApi('product_breadcrumb', { id: lastWord });
    const { data: associated, error: associatedError, isLoading: associatedLoading } = useApi('associated_testing_standards', { id: lastWord });
    const { data: associated2, error: associatedError2, isLoading: associatedLoading2 } = useApi('associated_testing_methods', { id: lastWord });

    // Handle errors
    if (error || associatedError || associatedError2) {
        console.error('API Error:', error || associatedError || associatedError2);
    }

    function onClickHandle(url, heading, Id) {
        const nameReplace = heading.replaceAll("/", "");
        const urlHeading = nameReplace.replaceAll(" ", "-") + "-" + (Id);
        history.push(`/${url}/${urlHeading}`);
    }

    return (
        <>
            <Header3 />
            <section
                className="page-banner-wrap-2 bg-cover"
                style={{ background: "white" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-12">
                            <div className="breadcrumb-wrap">
                                <nav>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page" onClick={() => onClickHandle("product-by-subcategory", data?.[0]?.category__Name, data?.[0]?.category__ID)}>
                                            {/* {"Product Details"} */}
                                            {data?.[0]?.category__Name}
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page" onClick={() => onClickHandle("product", data?.[0]?.subcategory__Name, data?.[0]?.subcategory__ID)}>
                                            {/* {"Product Details"} */}
                                            {data?.[0]?.subcategory__Name}
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            {/* {"Product Details"} */}
                                            {data?.[0]?.product__Name}
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="service-details-wrapper ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-5 mt-5 mt-md-0 col-12 order-1 order-md-1">
                            <div className="service-details-content-wrapper pl-0 pl-md-4">
                                <DetailsCarousel />
                                {/* <div>
                                    <div>
                                        <h6>Associated to Testing Standards: {JSON.stringify(associated)}</h6>
                                    </div>
                                    <div>
                                        <h6>Associated to Test Method(s): {JSON.stringify(associated2)}</h6>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5 col-12 order-2 order-md-2 service-detail-alignment" style={{ }}>
                            <ServiceDetails />
                        </div>
                    </div>
                </div>
            </section>
            <Footer3 />
        </>
    );
}

export default ServicesDetails;

