import React,{useEffect,useState,useContext} from 'react';
import axios from 'axios';

import ServicesOneCard from './ServicesOneCard';
import servicesOneData from './servicesOneData';

import Bg2 from '../../assets/img/home1/power.jpg';
import Icon1 from '../../assets/img/icon/s1.png';
import { UserContext } from '../../App';
import { useApi } from '../../middleware/middleware';

function Services6() {
    const {values} = useContext(UserContext)

    // const { data, error, isLoading } = useApi('products_by_standards_subcategory', {catID:values?.categoryId});
    const { data, error, isLoading } = useApi('products_by_standards_subcategory', {catID:values?.categoryId});

    function suCrypt(id) {
        return btoa(btoa(id));
    }
    // const [subCategories,setSubCategories] = useState(null)
    // useEffect(()=>{
    //     axios.get(process.env.REACT_APP_API_URL+"products_by_subcategory",{params:{catID:"44"}})
    //     .then(res=>setSubCategories(res?.data))
    // },[])
    return (
        <>
        <section className="services-wrapper services-1 section-bg section-padding">
            <div className="container">
                
                <div className="row">
                    <div className="col-12 col-lg-12">
                        <div className="section-title text-center">
                            <span>Subcategories</span>
                            <p>Our Sub Categories</p>
                            <h1>Sub CATEGORIES</h1>
                        </div>
                    </div>

                </div>
                {/* <div className="row" style={{marginTop:"20px"}}>
                    <img src={process.env.REACT_APP_API_URL +'cat_images/banner_'+suCrypt(values?.categoryId)+'.jpg'} />

                </div> */}
                <div className="row">
                    {
                    data!=null?
                    data?.map((item) => (
                        <ServicesOneCard
                            key={item.methods__ID}
                            sucategoryId={item.methods__ID}
                            bgImg={process.env.REACT_APP_IMAGE_URL +'standards_images/'+suCrypt(values?.categoryId)+'.jpg'}
                            icon={Icon1}
                            heading={item.methods__Name}
                            subHeading={item?.methods__Description}
                            btnText={"See Products"}
                        />
                    ))
                    :<></>
                
                }
                </div>
            </div>
        </section>
        </>
    );
}

export default Services6;
