import { FaDraftingCompass } from 'react-icons/fa';
import ServicesTwoCard from './SevicesTwoCard';
import ServicesTwoCard2 from './SevicesTwoCard2';

import { useApi } from '../../middleware/middleware';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';


export default function AllSearch(props) {
    // const { data, error, isLoading } = useApi('search_methods', { search: parseInt(props?.search) });
    const [data, setData] = useState([])
    useEffect(() => {
        // alert(props?.search)
        axios.get(process.env.REACT_APP_API_URL + 'search_methods', {
            params: {
                search: props?.search
            },
        })
            .then((response) => { setData(response?.data?.data) });
    }, [])

    return (
        <>
            <Swiper
                spaceBetween={40}
                autoplay
                className="portfolio-carousel-wrapper owl-carousel px-4"
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                    },

                    700: {
                        slidesPerView: 3,
                    },
                }}
            >
                {props?.data?.map((data, index) => (
                    <SwiperSlide>
                        <ServicesTwoCard2
                            key={data?.product__ID}
                            productId={data?.product__ID}
                            thumbnail={data?.product__ID}
                            defaultImg={process.env.REACT_APP_IMAGE_URL + 'images/product_testfabrics.jpg'}
                            icon={<FaDraftingCompass />}
                            heading={data?.product__Name}
                            text={data?.product__Description?.slice(0, 95) + "..." || ""}
                            subheading={data?.product__Number}
                            onHide={props.onHide}
                            searchType={props?.searchType}
                        />

                    </SwiperSlide>
                ))}
            </Swiper>
            {
                data!=[] &&
                <>
                    <h4>Test Methods: ({data?.length})</h4>
                    <Swiper
                        spaceBetween={40}
                        autoplay
                        className="portfolio-carousel-wrapper owl-carousel px-4"
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                            },

                            700: {
                                slidesPerView: 3,
                            },
                        }}
                    >
                        {data?.map((data, index) => (
                            <SwiperSlide>
                                <ServicesTwoCard2
                                    key={data?.product__ID}
                                    productId={data?.product__ID}
                                    thumbnail={data?.product__ID}
                                    defaultImg={process.env.REACT_APP_IMAGE_URL + 'images/product_testfabrics.jpg'}
                                    icon={<FaDraftingCompass />}
                                    heading={data?.product__Name}
                                    text={data?.product__Description?.slice(0, 95) + "..." || ""}
                                    subheading={data?.product__Number}
                                    onHide={props.onHide}
                                    searchType={props?.searchType}
                                />
                            </SwiperSlide>

                        ))}
                    </Swiper>
                </>
            }

{
                data &&
                <>
                    <h4>Standard Methods: ({data?.length})</h4>
                    <Swiper
                        spaceBetween={40}
                        autoplay
                        className="portfolio-carousel-wrapper owl-carousel px-4"
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                            },

                            700: {
                                slidesPerView: 3,
                            },
                        }}
                    >
                        {data?.map((data, index) => (
                            <SwiperSlide>
                                <ServicesTwoCard2
                                    key={data?.product__ID}
                                    productId={data?.product__ID}
                                    thumbnail={data?.product__ID}
                                    defaultImg={process.env.REACT_APP_IMAGE_URL + 'images/product_testfabrics.jpg'}
                                    icon={<FaDraftingCompass />}
                                    heading={data?.product__Name}
                                    text={data?.product__Description?.slice(0, 95) + "..." || ""}
                                    subheading={data?.product__Number}
                                    onHide={props.onHide}
                                    searchType={props?.searchType}
                                />
                            </SwiperSlide>

                        ))}
                    </Swiper>
                </>
            }
        </>
    )
}