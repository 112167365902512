
export default function Sewing
    () {
    return (
        <>
            <h3>Industrial Sewing Services</h3>
            <p>
            Testgabrics, Inc has got numerous successful sewing ventures on its account. We delivered exceptional custom sewing services with precision and expertise. We ensure clean finishing of the products with guaranteed durability. We specialize in creating samples allowing you to preview and if there is any adjustments to be made to further refine design details before it goes into mass production. Whether custom sample preparation, detailed stitching or innovative garment prototypes our skilled team will deliver no less than excellence.              </p>

            <br />

            <b>For Specialized Sewing Solutions </b>
            <b>Reach out for Further Information!</b>




        </>
    )

}