import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/img/logo-3.png';
import playstore from '../../assets/img/playStore.png';
import logoTf from '../../assets/img/logo-tf.png';
import appleStore from '../../assets/img/appleStore.png'
import MobileMenu from '../MobileMenu';
import { useApi } from '../../middleware/middleware';
import SearchService from '../SearchService';
import { LocationOn } from '@material-ui/icons';
// import FmdGoodIcon from '@mui/icons-material/FmdGood';
function MainMenu3() {
    // const { data, error, isLoading } = useApi('servecies_data', {});
    const { data, error, isLoading } = useApi('services_list', {});

    const { data: aboutData, error: error2, isLoading: isLoading2 } = useApi('about_data', {});
    const { data: location, error: locationError, isLoading: locationLoading } = useApi('get_ip_details', {});

    return (
        <header className="header-wrap header-3">
            <div className="container">
                <div className="row align-items-center justify-content-between">



                    <div className=" mobile-menu-bar col-lg-3 col-xl-2 col-2 col-md-2 text-right d-in-flex align-items-center">
                        <div className="col">
                            <MobileMenu aboutData={aboutData} serviceData={data} />
                        </div>
                    </div>
                    <div className="col-lg-2 col-4 col-md-4">
                        <div className="logo">
                            <Link to="/">
                                <img src={logo} alt="logo" height={30} style={{ margin: "10px 5px" }} />
                            </Link>
                        </div>
                    </div>
                    {/*<div className="col-lg-1 col-sm-8 col-3">*/}
                    {/*    <Link to="/choose-region">*/}
                    {/*        <LocationOn />*/}
                    {/*        <img width={"25px"} src={"https://tf.smilesdahub.com/apis/country_flags/" + location?.country_flag} />*/}
                    {/*        <br />*/}
                    {/*        */}{/* <div style={{ fontSize: "14px",lineHeight:"16px" }}>*/}{/*
                    */}{/*            {location?.city},*/}{/*
                    */}{/*        </div> */}
                    {/*        <div style={{ fontSize: "14px",lineHeight:"16px" }}>*/}
                    {/*            {location?.country}*/}
                    {/*        </div>*/}
                    {/*    </Link>*/}
                    {/*</div>*/}
                    <div className="col-lg-1 col-sm-8 col-3">
                        <Link to={`/choose-region/${location?.country}`}>
                            <LocationOn />
                            <img width={"25px"} src={"https://tf.smilesdahub.com/apis/country_flags/" + location?.country_flag} />
                            <br />
                            <div style={{ fontSize: "14px", lineHeight: "16px" }}>
                                {location?.country}
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-6 pl-lg-0 ">
                        <div className="">
                            <SearchService />

                            {/* <ul>
                                <li>
                                    <Link to="/about">About Us</Link>
                                    <ul className="sub-menu">
                                    {aboutData?.map((item)=>{
                                             return(
                                                <li key={item?.associations_and_partners__Name}>
                                                    <Link to={{pathname:"/project-details",state: { item:item?.associations_and_partners__Description,heading:item?.associations_and_partners__Name, category:"About US"}}}>{item?.associations_and_partners__Name}</Link>
                                                </li>
                                            )
                                        })}
                                        <li>
                                            <Link to={'/associate-and-patners'}>ASSOCIATION AND PARTNERS</Link>
                                        </li>
                                        <li>
                                            <Link to={'/carrers'}>CAREERS</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link to="">Products</Link>
                                    <ul className="sub-menu">
                                        <li>
                                            <Link to="/product-by-category">Browse By Product Category</Link>
                                        </li>
                                        <li>
                                            <Link to="/product-by-standards">Browse By Standard test Method</Link>
                                        </li>
                                        <li>
                                            <Link to="/product-by-category">Browse By Interest Group</Link>
                                        </li>
                                       
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/equipment-category">Equipment</Link>
                                    <ul className="sub-menu">
                                        <li>
                                            <Link to="/equipment-category">Browse By Equipment Category</Link>
                                        </li>
                                        
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/services">Services</Link>
                                    <ul>
                                        {data?.map((item)=>{
                                             return(
                                                <li key={item?.associations_and_partners__ID}>
                                                    <Link to={{pathname:"/project-details",state: { item:item?.associations_and_partners__Description,heading:item?.associations_and_partners__Name,category:item?.cat_name }}}>{item?.associations_and_partners__Name}</Link>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </li>

                               
                                <li>
                                    <Link to="/contact">Support</Link>
                                    <ul className="sub-menu">
                                    <li><Link to="/request-certifiate">REQUEST CERTIFICATE OF CONFORMITY</Link></li>
                                    <li><Link to="/msds-request"> REQUEST MATERIAL SAFETY DATA SHEET(MSDS)</Link></li>
                                    <li><Link to="/contact">ORDER REQUEST</Link></li>
                                    <li><Link to="/contact">QUOTE REQUEST</Link></li>
                                    <li><Link to="/track-order">TRACK YOUR ORDER</Link></li>
                                    <li><Link to="/downloads">DOWNLOADS</Link></li>
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/contact">Contact Us</Link>
                                    <ul className="sub-menu">
                                    <li><Link to="/choose-region" >Chose Your Country or Region</Link></li>
                                    <li><Link to="/swatch-request" >FABRIC SWATCH REQUEST</Link></li>
                                    <li><Link to="/catalog-request">CATALOG REQUEST</Link></li>
                                    <li><Link to="/business-inquiry">BUSINESS INQUIRY REQUEST</Link></li>
                                    </ul>
                                </li>
                            </ul> */}
                        </div>

                    </div>
                    <div className='col-lg-2 download-icons' style={{ zIndex: "7" }}>
                        <div className='row'>
                            {/* <div className='col-4' style={{display:"flex",justifyContent:"center",alignItems:"center"}}> <img src={logoTf} height={35} /></div> */}
                            <div className='' style={{display:"flex",flexDirection:"row"}}>
                                <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                <a href='https://play.google.com/store/apps/details?id=com.tech.testfabrics.test_fabrics' target='_blank'>
                                    <img style={{ borderRadius: "15%" }} src={playstore} width={"100px"} height={"35px"} />
                                </a>
                                </div>
                                <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                <a href='https://apps.apple.com/us/app/testfabrics-product-validation/id1660715463' target='_blank'>
                                    <img style={{ borderRadius: "15%" }} src={appleStore} width={"100px"} height={"55px"} />
                                </a>
                                </div>
                               
                            </div>

                        </div>


                    </div>
                    {/* <div className="col-lg-3 col-xl-2 col-6 col-md-5 text-right d-in-flex align-items-center">
                        <Link to="/contact" className="theme-btn theme-3">
                            Get A Quote
                        </Link>

                        <div className="col mobile-menu-bar">
                            <MobileMenu aboutData={aboutData} serviceData={data}/>
                        </div>
                    </div> */}
                </div>
            </div>
        </header>
    );
}

export default MainMenu3;
