import React from 'react';
import bannerBg from '../assets/img/page-banner.jpg';
import Footer1 from '../components/Footer1';
import Header1 from '../components/Header1';
import PageBanner from '../components/PageBanner';
import Process from '../components/Process';
import ProjectFilter2 from '../components/ProjectFilter2';
import Services2 from '../components/Services2';
import Services1 from '../components/Services1';

import Header3 from '../components/Header3';
import Services5 from '../components/Services5';
import Footer3 from '../components/Footer3';

function ProductsByStandards() {
    return (
        <>
            <Header3 />
            {/* <PageBanner bannerBg={bannerBg} currentPage="STANDARD TEST METHOD" heading="STANDARD TEST METHOD" /> */}
            <section
            className="page-banner-wrap-2 "
            // style={{ backgroundImage: `url(${bannerBg})` }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-12">
                            <div className="breadcrumb-wrap">
                                <nav>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <a href="index.html">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            {"STANDARD TEST METHOD"}
                                        </li>
                                    </ol>
                                </nav>
                            </div>

                            {/* <div className="page-heading">
                                <h2>{"STANDARD TEST METHOD"}</h2>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
            <Services5 heading1={"Categories"} heading2={""} heading3={"STANDARD TEST METHOD"} apiName={"products_by_standard_methods"} />
            {/* <Process />
            <ProjectFilter2 /> */}
            <Footer3 />
        </>
    );
}

export default ProductsByStandards;
