import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa';

function SearchWidget(props) {
    // STATES
    // const [search, setSearch] = useState('');
    // HANDLER
    const searchHandler = (e) => {
        props.setSearch(e.target.value);
    };

    const submitHandler = (e) => {
        e.preventDefault();
        props.searchFunction(e)
    };

    return (
        <div className="single-sidebar-widget">
            {/* <div className="wid-title">
                <h3>Search By Name Or Category</h3>
            </div> */}
            <div className="search_widget">
                <form onSubmit={submitHandler} style={{display:"flex"}}>
                    <select onChange={(e)=>{props?.setSearchType(e.target.value)}}>
                        <option value={1}>All</option>
                        {/* <option value={2}>category</option> */}
                        <option value={2}>Product</option>
                        <option value={3}>Test Standard</option>
                        <option value={4}>Interest Group</option>
                    </select>
                    <input
                        className='search-field'
                        value={props.search}
                        onChange={searchHandler}
                        type="text"
                        placeholder="Search Testfabrics Products and Global Test Methods"
                    />
                    <button  type="submit">
                        <FaSearch />
                    </button>
                </form>
            </div>
        </div>
    );
}

export default SearchWidget;
