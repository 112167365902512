import React, { useContext } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { Link,useHistory } from "react-router-dom";
import { UserContext } from '../../App';

function ServicesOneCard({index,categoryId ,bgImg, icon, heading, btnText, defaultImg ,alterImg}) {
    const history = useHistory();
    const {setValues} = useContext(UserContext)
    function suCrypt(id) {
        return btoa(btoa(id));
    }
    function handleClick(){
        setValues((pre)=>({...pre,categoryId:categoryId}))
        const urlHeading = heading.replaceAll(" ", "-").replaceAll("/", "")+"-"+(categoryId); ;
        history.push(`/product-by-subcategory/${urlHeading}`)
        // history.push("/product-by-subcategory")

    }
    function onError(e){
        e.target.src = bgImg||defaultImg
    }
// defaultImg={process.env.REACT_APP_IMAGE_URL + 'images/product_testfabrics.jpg'}

    return (
        <div className="col-md-6 col-xl-3 col-12"  >
            <div className="single-service-item service-1" onClick={handleClick} style={{cursor:"pointer",minHeight:"350px" }}>
                <div
                    className="service-bg bg-cover"
                    // style={{
                    //     backgroundImage: `url(${bgImg})`,
                    // }}
                >
                    <img style={{height:"100%",width:"100%"}} src={alterImg} onError={(e) => onError(e)}  />
                    {/* <img src={'https://smilesdahub.com/tf/apis/cat_images/banner_'+suCrypt(lastWord)+'.jpg'}  /> */}

                </div>
                {/* <div className="icon">
                    <img src={icon} alt="icon" />
                </div> */}
                <h3>{index}. {heading}</h3>
                <div className="">
                    {/* <img src={icon} alt="icon" /> */}
                </div>
                <a onClick={handleClick}>
                    <span>{btnText}</span>
                    <BsArrowRight style={{ fontSize: '18px' }} />
                </a>
            </div>
        </div>
    );
}

export default ServicesOneCard;
