import { Link } from 'react-router-dom';
import React from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useApi } from '../../middleware/middleware';
import logo from '../../assets/img/logo-3.png';
import { ArrowDropDown } from '@material-ui/icons';

function Topbar3() {
    const { data, error, isLoading } = useApi('services_list', {});
    const history = useHistory();

    const handleServiceClick = (service) => {
        axios.get(`https://tf.smilesdahub.com/apis/index.php/services_list_detail?service_id=${service.associations_and_partners__ID}`)
            .then(response => {
                console.log('API response:', response.data);
                const responseData = response.data;
                if (responseData.success && responseData.data.length > 0) {
                    const serviceDetail = responseData.data[0];
                    console.log('the item is ', serviceDetail.associations_and_partners__Description,)
                    console.log('the heading is ', serviceDetail.associations_and_partners__Name,)
                    console.log('the catogory is ', serviceDetail.cat_name,)
                    const formattedName = serviceDetail.associations_and_partners__Name.replace(/ /g, '_');
                    history.push({
                        pathname: `/services/${formattedName}`,
                        state: {
                            item: serviceDetail.associations_and_partners__Description,
                            heading: serviceDetail.associations_and_partners__Name,
                            category: serviceDetail.cat_name
                        }
                    });
                    console.log('Navigating to:', `/project-details/${serviceDetail.associations_and_partners__Name}`);
                } else {
                    console.error('No data found for service:', service);
                }
            })
            .catch(error => {
                console.error('Error fetching service details:', error);
            });
    };

    return (
        <header className="desktop-menu header-wrap header-5">
            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-lg-12 pl-lg-3 header-none">
                        <div className="main-menu">
                            <ul>
                                <li>
                                    <Link to="">Products<ArrowDropDown/></Link>
                                    <ul className="sub-menu">
                                        <li>
                                            <Link to="/product-by-category">Browse By Product Category</Link>
                                        </li>
                                        <li>
                                            <Link to="/product-by-standards">Browse By Standard test Method</Link>
                                        </li>
                                        <li>
                                            <Link to="/product-by-category">Browse By Interest Group</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/equipment-category">Equipment<ArrowDropDown/></Link>
                                    <ul className="sub-menu">
                                        <li>
                                            <Link to="/equipment-category">Browse By Equipment Category</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/">Services<ArrowDropDown /></Link>
                                    <ul>
                                        {data?.map((item) => (
                                            <li key={item?.associations_and_partners__ID} onClick={() => handleServiceClick(item)}>
                                                <Link to={`/services/${item?.associations_and_partners__Name.replace(/ /g, '_')}`}>
                                                    {item?.associations_and_partners__Name}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/">Support<ArrowDropDown/></Link>
                                    <ul className="sub-menu">
                                        <li><Link to="/request-certifiate">REQUEST CERTIFICATE OF CONFORMITY</Link></li>
                                        <li><Link to="/msds-request"> REQUEST MATERIAL SAFETY DATA SHEET(MSDS)</Link></li>
                                        <li><Link to="/contact">ORDER REQUEST</Link></li>
                                        <li><Link to="/contact">QUOTE REQUEST</Link></li>
                                        <li><Link to="/track-order">TRACK YOUR ORDER</Link></li>
                                        <li><Link to="/downloads">DOWNLOADS</Link></li>
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/contact">Contact us<ArrowDropDown /></Link>
                                    <ul className="sub-menu">
                                        <li>
                                            <Link to="/choose-country">Choose your Country or Region</Link>
                                        </li>
                                        <li>
                                            <Link to="/swatch-request">Fabric Swatch Request</Link>
                                        </li>
                                        <li>
                                            <Link to="/catalog-request">Catalog Request</Link>
                                        </li>
                                        <li>
                                            <Link to="/business-inquiry">Business Inquiry Form</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/contact">Discover us<ArrowDropDown /></Link>
                                    <ul className="sub-menu">
                                        <li>
                                            <Link to="/heritage">Heritage</Link>
                                        </li>
                                        <li>
                                            <Link to="/team">Team</Link>
                                        </li>
                                       
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/contact">Verification <ArrowDropDown /></Link>
                                    <ul className="sub-menu">
                                        <li><Link to="/product-verification">Validate Your Product Here</Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Topbar3;
