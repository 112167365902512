import React from 'react';
import Footer3 from '../components/Footer3';
import Header3 from '../components/Header3';


function DetailPage() {
    return (
        <>
            <Header3 />

            <Footer3 />
        </>
    );
}

export default DetailPage;
