import React,{useContext} from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { Link,useHistory } from "react-router-dom";
import { UserContext } from '../../App';

function ServicesOneCard({ sucategoryId,bgImg, icon, heading, btnText, subHeading }) {
    const history = useHistory();
    const {setValues} = useContext(UserContext)

    function handleClick(){
        setValues((pre)=>({...pre,sucategoryId:sucategoryId}))
        // console.log("sucategoryId : ",sucategoryId)
        history.push(`/standard-product/${sucategoryId}`)
    }
    return (
        <div className="col-md-6 col-xl-3 col-12">
            <div className="single-service-item service-1">
                <div
                    className="service-bg bg-cover"
                    style={{
                        backgroundImage: `url(${bgImg})`,
                    }}
                />
                {/* <div className="icon">
                    <img src={icon} alt="icon" />
                </div> */}
                <h3>{heading}</h3>
                <h5>{subHeading}</h5>
                <a onClick={handleClick}>
                    <span>{btnText}</span>
                    <BsArrowRight style={{ fontSize: '18px' }} />
                </a>
            </div>
        </div>
    );
}

export default ServicesOneCard;
