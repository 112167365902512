import img1 from '../../assets/img/kappa.jpg';

const aboutTwoData = {
    img: img1,
    heading: 'Dustrix is always interested for doing awesome work.',
    quote: 'Testfabrics is a world-leading producer and distributor of textile testing media. They provide a wide range of services to their clients, including quality assurance testing, colorfastness testing, and physical testing. Testfabrics is committed to providing their clients with the highest quality products and services.',
    author: 'Miranda H.',
    position: '- Founder',
    text: 'Testfabrics is a world-leading producer and distributor of textile testing media. They offer a wide range of textile testing services to meet the needs of their clients, including quality assurance testing, colorfastness testing, physical testing, and flammability testing. fruit to identify a ballpark value added',
};

export default aboutTwoData;
