import React from 'react';
import MainMenu3 from './MainMenu3';
import Topbar3 from './Topbar3';

function Header3({data=null}) {
    return (
        <>
           
            <MainMenu3 />
            <Topbar3 />
        </>
    );
}

export default Header3;
