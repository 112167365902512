import { Link } from "@material-ui/core";
import { useApi } from "../../middleware/middleware";
import Parser from 'html-react-parser';

import { useState } from "react";
import CountryDetailModel from "./model";


export default function CountrySection(){
    const [modalShow, setModalShow] = useState(false);
    const [countryId, setCountryId] = useState({id:0,name:"",show:false});

    const { data, error, isLoading } = useApi('pages_data', {page_id:43});
    const { data:region_data, error:region_error, isLoading:region_loading } = useApi('region_data', {});
    const { data:country_data, error:country_error, isLoading:country_loading } = useApi('map_contries_data', {});
    

    function suCrypt(id) {
        // console.log("countryyyy code : ",btoa(btoa(id)))
        return btoa(btoa(id));
    }
    function handleClick(id,name){
        setCountryId(()=>({id:id,name:name,show:true}));
        // setModalShow(true);
    }

    return(
        <div className="container">
            <CountryDetailModel
                show={countryId.show}
                onHide={() => setCountryId((pre)=>({...pre,show:false}))}
                countryid={countryId}
            />
        <h5>
            {data && Parser(data?.[0]?.page_long_content)}
        </h5>
        <br/>
        <hr/>
        <br/>
        {region_data&&region_data?.map((item)=>
            <>
                <div style={{padding:"10px "}}>
                    <h4>{item?.map__Name}</h4>
                    <div  className="row" style={{marginTop:"10px", marginLeft:"10px"}} >
                        {country_data?.map((county)=>{
                            return(
                                county.countries__Map_ID==item.map__ID&&
                                <div className="col-3 mb-3" style={{cursor:"pointer", }} onClick={()=>handleClick(county?.countries__ID,county?.countries__Name)}>
                                    {/* `url(http://localhost:8888/product_images/${suCrypt()}.jpg)` */}
                                    {/* <img src={'http://localhost:8888/product_images/'+suCrypt(county?.map__ID)+'.jpg'} /> */}
                                    <div className="row" style={{width:"30%"}}>
                                        <img  width="25" style={{marginRight:"5px"}} src={process.env.REACT_APP_IMAGE_URL +'map_images/'+suCrypt(county?.countries__ID)+'.jpg'} />
                                    </div>
                                    <div className="row">
                                        <h6>{county?.countries__Name}</h6>
                                    </div>
                                    {/* <br/> */}
                                </div>
                            )
                        }
                        )}
                    </div>
                </div>
                <br/>
            </>
        )}

        </div>
    )
}