import React, { useContext, useState, useEffect } from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from '../../App';
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton } from 'react-share';

SwiperCore.use([Navigation, Pagination]);

function DetailsCarousel() {
    const { values } = useContext(UserContext);
    const location = useLocation();
    const pathData = location?.pathname;
    const words = pathData?.split("-");
    const lastHyphenatedWord = words?.reverse()?.find(word => word.startsWith("-"));
    const lastWord = words[0];

    const [selectedImage, setSelectedImage] = useState(0);
    const [imagesData, setImagesData] = useState([]);
    const defaultImage = process.env.REACT_APP_IMAGE_URL + "product_images/T0E9PQ==_a.jpg";

    useEffect(() => {
        async function fetchImages() {
            try {
                const response = await axios.get(`https://tf.smilesdahub.com/apis/index.php/product_media?product_id=${lastWord}`);
                const imageData = response.data.data; // Assuming the API response is an array under 'data'
                setImagesData(imageData);
            } catch (error) {
                console.error("Error fetching images:", error);
            }
        }
        fetchImages();
    }, [lastWord]);

    function onError(e) {
        e.target.src = defaultImage;
    }

    return (
        <div>
            <div className='custom-crousel'>
                <div className='crousel-sidebar' style={{height:"400px"}}>
                    <Swiper
                        slidesPerView={4}
                        // spaceBetween={30}
                        // centeredSlides
                        // loop
                        direction='vertical'
                        className="timeline-carousel-wrapper"
                        breakpoints={{
                            0: {
                                slidesPerView: 4,
                            },
                            576: {
                                slidesPerView: 4,
                            },

                            768: {
                                slidesPerView: 4,
                            },

                            992: {
                                slidesPerView: 4,
                            },
                        }}
                    >
                        {imagesData.map((item, index) => (
                            <div className='crousel-sidebar-image' onClick={() => setSelectedImage(index)} key={index}>
                                {item.media_type === 'video' ? (
                                    <SwiperSlide key={item.id}>
                                    <video width="100%" src={`https://smilesdahub.com/tf/apis/product_images/${item.media_name}`}
                                    onClick={()=>{setSelectedImage(index)}} 
                                    autoPlay muted loop />
                                    </SwiperSlide>
                                ) : (
                                    <SwiperSlide key={item.id}>
                                        <img style={{ objectFit: "fill" }} src={`https://smilesdahub.com/tf/apis/product_images/${item.media_name}`} onError={onError} 
                                        onClick={()=>{setSelectedImage(index)}} 
                                        className={selectedImage == index ? 'activeSwiperImage' : ""} />
                                    </SwiperSlide>
                                )}
                            </div>
                        ))}
                    </Swiper>
                </div>
                <div className='crousel-image'>
                    {imagesData[selectedImage]?.media_type === 'video' ? (
                        <video width={"100%"} height={"50%"} src={`https://smilesdahub.com/tf/apis//product_images/${imagesData[selectedImage].media_name}`} muted autoPlay loop></video>
                    ) : (
                        <div style={{ position: "relative" }}>
                            <div style={{ position: "absolute", right: "0", display: "flex", flexDirection: "column" }}>
                                <FacebookShareButton url={'https://www.testFabric.com'} quote={'TestFabric'} hashtag="#muo" style={{ margin: "4px 10px" }}>
                                    <FacebookIcon size={32} round />
                                </FacebookShareButton>
                                <LinkedinShareButton url={'https://www.testFabric.com'} quote={'TestFabric'} hashtag="#muo" style={{ margin: "4px 10px" }}>
                                    <LinkedinIcon size={32} round />
                                </LinkedinShareButton>
                                <EmailShareButton url={'https://www.testFabric.com'} quote={'TestFabric'} hashtag="#muo" style={{ margin: "4px 10px" }}>
                                    <EmailIcon size={32} round />
                                </EmailShareButton>
                            </div>
                            <img style={{ width: "100%", borderRadius: "12px" }} src={`https://smilesdahub.com/tf/apis//product_images/${imagesData[selectedImage]?.media_name}`} onError={onError} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default DetailsCarousel;
