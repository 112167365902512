import React, { useState } from 'react';
import axios from 'axios';
import qs from 'qs'; // For URL encoding
import { FaCheckCircle, FaTimesCircle, FaCircle } from 'react-icons/fa'; // Icons
import image from '../assets/img/image.jpg';
import Header3 from '../components/Header3';
import Footer3 from '../components/Footer3';

function Verification() {
    const [serialNumber, setSerialNumber] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: '100vh',
        padding: '20px',
    };

    const imageStyle = {
        maxWidth: '100%',
        height: 'auto',
        marginBottom: '20px',
    };

    const formStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    };

    const inputStyle = {
        padding: '10px',
        margin: '10px 0',
        width: '300px',
        border: '1px solid #ccc',
        borderRadius: '4px',
    };

    const buttonStyle = {
        padding: '10px 20px',
        backgroundColor: '#007bff',
        border: 'none',
        borderRadius: '4px',
        color: '#fff',
        cursor: 'pointer',
        fontSize: '16px',
    };

    const handleChange = (e) => {
        setSerialNumber(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setResponseMessage('');
        setError('');

        try {
            const response = await axios.post('https://tf.smilesdahub.com/apis/index.php/tpvs_search', qs.stringify({
                search: serialNumber,
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            });

            // Log the entire response object for debugging
            console.log('API Response:', response);

            const { message } = response.data;

            // Log the message for debugging
            console.log('Response Message:', message);

            // Handle response based on the message
            if (message.includes('Record updated and data inserted successfully')) {
                setResponseMessage({ text: message, type: 'success' });
            } else if (message.includes('Already Verified')) {
                setResponseMessage({ text: message, type: 'verified' });
            } else if (message.includes('No Record Found')) {
                setResponseMessage({ text: message, type: 'error' });
            } else {
                setResponseMessage({ text: 'Unknown response', type: 'error' });
            }
        } catch (err) {
            console.error('Error:', err); // Debugging: log the error
            setError('Error submitting form. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const getMessageStyle = (type) => {
        switch (type) {
            case 'success':
                return { color: 'green' };
            case 'verified':
                return { color: 'blue' };
            case 'error':
                return { color: 'red' };
            default:
                return {};
        }
    };

    const getMessageIcon = (type) => {
        switch (type) {
            case 'success':
                return <FaCheckCircle style={{ marginRight: '10px', color: 'green' }} />;
            case 'verified':
                return <FaCircle style={{ marginRight: '10px', color: 'blue' }} />;
            case 'error':
                return <FaTimesCircle style={{ marginRight: '10px', color: 'red' }} />;
            default:
                return null;
        }
    };

    return (
        <>
            <Header3 />
            <div style={containerStyle}>
                <h2>Welcome to Testfabrics Product Validation System</h2>
                <img src={image} alt="Image not Found" style={imageStyle} />

                <div className="content" style={formStyle}>
                    <p>To Validate your Testfabrics product please enter your Serial Number below:</p>

                    <form onSubmit={handleSubmit}>
                        <input
                            type="text"
                            id="serialNumber"
                            name="serialNumber"
                            placeholder="Enter Serial Number"
                            value={serialNumber}
                            onChange={handleChange}
                            style={inputStyle}
                        />
                        <br />
                        <button type="submit" style={buttonStyle} disabled={loading}>
                            {loading ? 'Submitting...' : 'Submit'}
                        </button>
                    </form>

                    {responseMessage && (
                        <h3 style={{ marginTop: '20px', ...getMessageStyle(responseMessage.type) }}>
                            {getMessageIcon(responseMessage.type)}
                            {responseMessage.text}
                        </h3>
                    )}
                    {error && <p style={{ marginTop: '20px', color: 'red' }}>{error}</p>}
                </div>
            </div>
            <Footer3 />
        </>
    );
}

export default Verification;

